import React from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { OrdinalColorScaleConfig } from '@nivo/colors';

import { COLORS, FONT_FAMILY_MAIN } from '~utils/styling';
import { strings } from '~utils/strings';

type BarChartProps = {
  data: BarDatum[];
  indexBy: string;
  keys: string[];
  colors: OrdinalColorScaleConfig;
  onClick: (data: any) => void;
  layout: 'horizontal' | 'vertical' | undefined;
  showLabel: boolean;
  tooltip?: (data: any, color: string) => JSX.Element;
  tooltipLabel?: (d: any) => string;
  FormattedAxis?: (props: any) => JSX.Element;
  margin?: Record<string, number>;
};

const BarChart = ({
  data,
  keys,
  indexBy,
  colors,
  onClick,
  tooltip,
  tooltipLabel,
  layout,
  showLabel,
  FormattedAxis,
  margin,
}: BarChartProps) => {
  const Label = (props: any) => {
    const { bars, onClick } = props;

    if (showLabel) {
      return bars.map((bar: any) => {
        const {
          key,
          height,
          x,
          y,
          data: { indexValue },
        } = bar;
        return (
          <g
            key={key}
            transform={
              layout === strings.noTranslate.horizontal
                ? `translate(${x}, ${y})`
                : `translate(${x + 25}, ${y + height}) rotate(-90)`
            }
            onClick={() => onClick(bar.data)}
          >
            <text
              transform={`translate(10, ${
                layout === strings.noTranslate.horizontal ? height / 2 + 4 : 0
              })`}
              textAnchor="left"
              fontSize="14px"
              fontFamily={FONT_FAMILY_MAIN}
              fontWeight={600}
              fill={COLORS.CORE.WHITE}
              pointerEvents="none"
            >
              {indexValue}
            </text>
          </g>
        );
      });
    }

    return <></>;
  };

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      colors={colors}
      colorBy="indexValue"
      layout={layout}
      margin={showLabel ? {} : margin}
      enableLabel={false}
      enableGridY={false}
      labelTextColor={COLORS.CORE.WHITE}
      tooltip={tooltip ? ({ data, color }) => tooltip(data, color) : undefined}
      tooltipLabel={tooltipLabel ? (d) => tooltipLabel(d) : undefined}
      layers={[
        'grid',
        !showLabel ? (props) => <FormattedAxis {...props} /> : <></>,
        'bars',
        (props) => <Label {...props} />,
        'markers',
        'legends',
        'annotations',
      ]}
      padding={0}
      onClick={onClick}
      onMouseEnter={(_data: any, event: any) => {
        event.target.style.opacity = 0.8;
        event.target.style.cursor = 'pointer';
      }}
      onMouseLeave={(_data: any, event: any) => {
        event.target.style.opacity = 1;
      }}
    />
  );
};

export default BarChart;
