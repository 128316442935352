import React from 'react';
import BootstrapIcons from 'bootstrap-icons/bootstrap-icons.svg';

import { COLORS } from '~utils/styling';

type IconProps = {
  name: string;
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
};

const defaultProps: IconProps = {
  name: '',
  width: '32px',
  height: '32px',
  fill: COLORS.CORE.BLACK,
};

const Icon = (props: IconProps): JSX.Element => {
  const { name, ...attrs } = props;

  return (
    <svg role="img" focusable="false" aria-label={name} {...attrs}>
      <use xlinkHref={`${BootstrapIcons}#${name}`} />
    </svg>
  );
};

Icon.defaultProps = defaultProps;

export default React.memo(Icon);
