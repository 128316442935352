import * as React from 'react';
import { COLORS } from '~utils/styling';

const SnoozeIcon = (): JSX.Element => (
  <svg
    style={{ marginRight: '1rem' }}
    width="14"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m11.56 8.13.78.97c.36.44.44 1.03.2 1.54l-.05-.02c-.23.49-.72.8-1.26.8l.33-3.29Zm0 0a1.6 1.6 0 0 1-.36-1.02V6.07a4.1 4.1 0 0 0-2.46-3.78v-.02c0-.73-.59-1.32-1.32-1.32h-.7c-.73 0-1.33.6-1.33 1.32v.02a4.13 4.13 0 0 0-2.45 3.78V7.1c0 .38-.13.72-.36 1.02l-.78.97a1.44 1.44 0 0 0 1.1 2.36h1.68a2.5 2.5 0 0 0 4.97 0l2-3.33Zm-.05 2.02a.32.32 0 0 1-.3.19H2.9c-.18 0-.26-.12-.3-.18-.03-.07-.08-.21.04-.35l.79-.97c.39-.48.6-1.1.6-1.71V6.08a3 3 0 0 1 2.08-2.86c.23-.07.38-.3.38-.54v-.4c0-.1.1-.2.2-.2h.7c.1 0 .2.1.2.2v.4c0 .25.17.46.38.54a3 3 0 0 1 2.08 2.86v1.05c0 .62.21 1.23.6 1.71l.79.97c.08.08.1.15.1.2.01.07-.01.12-.03.14Zm-4.45 2.38c-.66 0-1.2-.46-1.34-1.07H8.4a1.38 1.38 0 0 1-1.34 1.07Z"
      fill={COLORS.CORE.PRIMARY}
      stroke={COLORS.CORE.PRIMARY}
      strokeWidth=".1"
    />
    <path
      d="M8.18 7.6H7.1l1.5-1.9c.13-.18.16-.4.08-.6a.57.57 0 0 0-.51-.32H5.95a.57.57 0 0 0 0 1.14h1.07l-1.51 1.9a.59.59 0 0 0-.07.59c.09.19.29.32.5.32H8.2c.3 0 .57-.25.57-.57a.57.57 0 0 0-.58-.57Z"
      fill={COLORS.CORE.PRIMARY}
      stroke={COLORS.CORE.PRIMARY}
      strokeWidth=".1"
    />
  </svg>
);

export default SnoozeIcon;
