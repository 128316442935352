import { COLORS } from '~utils/styling';

export const dateFormats = {
  FORMAT_FULL_DATE_FULL_YEAR: 'M/D/YYYY',
  FORMAT_FULL_DATE: 'm/d/Y',
  FORMAT_HOUR_MERIDIEM: '',
  FORMAT_M_D: 'M/D',
  FORMAT_WORKLOADS: '%m-%d %I:%M %p',
  C3_FORMAT_M_D: '%m/%d',
  C3_FORMAT_FULL_DATE: '%m/%d/%Y',
};

export const colorScheme = {
  critical: COLORS.SEVERITY.CRITICAL,
  high: COLORS.SEVERITY.HIGH,
  medium: COLORS.SEVERITY.MEDIUM,
  low: COLORS.SEVERITY.LOW,
  none: COLORS.SEVERITY.NONE,
  passing: COLORS.CORE.SUCCESS[100],
  unknown: COLORS.CORE.LINK,
};

export const MOUNTED_DELAY_TIMEOUT = 500;
